import React from 'react';
import * as S from './Body.styled';
import { getNextMeeting, CADENCE } from '../utils/nextMeeting';

const CenterLogo = () => {
  return ( 
    <>
    <S.Subtitle>
      <p>Join Broad Ripple Code and Coffee every other Wednesday morning anytime from 8-11(ish) ET at <a href="https://maps.app.goo.gl/TyZ3DBbUaiwgtwUt5">Mariam Coffee</a>.</p>
      <p>Next Meetup: <b><code>{getNextMeeting(CADENCE.BI_WEEKLY_A)}</code></b></p>
      <S.Divider />
      <p>Join Indy Virtual Code and Coffee on opposing Wednesdays, bi-weekly at 8am Eastern time. Join us <a href="https://www.meetup.com/indianapolis-virtual-code-and-coffee/">virtually</a>!</p>
      <p>Next Meetup: <b><code>{getNextMeeting(CADENCE.BI_WEEKLY_B)}</code></b></p>
   </S.Subtitle>
   <S.Footer>
     Talk with us on <a href="https://www.indyhackers.org/">Indy Hackers</a>
   </S.Footer> 
    </>
  )
};

export default CenterLogo;