import styled from 'styled-components';
import { devices } from '../utils/breakpoints';

export const Subtitle = styled.div`
  margin-top: 5%;
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 36px;
  max-width: 400px;

  @media ${devices.mobileLandscape} {
    margin-top: 0;
    font-size: 16px;
    line-height: 24px;
  }

  @media ${devices.mobilePortrait} {
    margin: 15% 16px;
    font-size: 16px;
    line-height: 24px;
  }
`;

export const Footer = styled.div`
  position: absolute;
  left: 32px;
  bottom: 32px;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-weight: 400;
`;

// TODO: these are the same background image and animation as for the main container.
//       we should probably move them to a shared component.
export const Divider = styled.hr`
  border: 0;
  height: 5px;
  border-radius: 8px;
  background-image: linear-gradient(-45deg, #c1ed3d, #ff8e1f, #3d7df7, #81fd5c);
  background-size: 400% 400%;
  animation: gradient 10s ease infinite;
  width:50%;
  margin:0 auto;

  @keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
`;

export const Callout = styled.p`
  background-color: yellow;
  color: black;
  border-radius: 4px;
  font-size: 16px;
  line-height: 20px;
  padding: 8px;
  text-align: center;
`;