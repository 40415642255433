import styled from 'styled-components';
import { devices } from '../utils/breakpoints';

export const Body = styled.div`
  height: 100%;
  background: linear-gradient(-45deg, #c1ed3d, #ff8e1f, #3d7df7, #81fd5c);
	background-size: 400% 400%;
	animation: gradient 10s ease infinite;

  @keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
`;

export const Margin = styled.div`
  padding: 16px;
  height: calc(100% - 32px);
`;

export const Main = styled.div`
  background-color: #282c34;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  border-radius: 8px;
  font-family: 'Inter', sans-serif;

  @media ${devices.mobileLandscape} {
    flex-direction: row;
    gap: 36px;
  }
`;