import skipJson from '../assets/skip.json';

const oneDay = 1000 * 60 * 60 * 24;
const twoWeeks = oneDay * 14;
const originalDateA = new Date("2025-02-12T08:30:00.000-05:00");
const originalDateB = new Date("2025-02-05T08:30:00.000-05:00");

function getNextWednesday(date = new Date()) {
  const dateCopy = new Date(date.getTime());

  const nextWednesday = new Date(
    dateCopy.setDate(
      dateCopy.getDate() + ((7 - dateCopy.getDay() + 3) % 7 || 7),
    ),
  );

  return nextWednesday;
}

function getFirstWednesday(date = new Date()) {
  let dateCopy = new Date(date.getTime());

  do {
    dateCopy = getNextWednesday(dateCopy);
  } while (dateCopy.getDate() > 7)

  return dateCopy;
}

export const CADENCE = Object.freeze({
  BI_WEEKLY_A: 'bi-weekly-a',
  BI_WEEKLY_B: 'bi-weekly-b',
  MONTHLY: 'monthly',
});

export const getNextMeeting = (cadence, now = new Date(), skip = skipJson) => {
  let next;

  const isWednesday = now.getDay() === 3;

  if (cadence === CADENCE.BI_WEEKLY_A || cadence === CADENCE.BI_WEEKLY_B) {
    const originalDate = cadence === CADENCE.BI_WEEKLY_A ? originalDateA : originalDateB;
    const difference = (now.valueOf() - originalDate.valueOf()) % twoWeeks;
    
    if (twoWeeks - difference < oneDay && isWednesday) next = now;
    else if (difference < twoWeeks / 2) next = getNextWednesday(getNextWednesday(now));
    else next = getNextWednesday(now);
  } else if (cadence === CADENCE.MONTHLY) {
    
    // if today is the first wednesday of the month before 8:30am, return today
    if (isWednesday && now.getDate() < 7 && now.getHours() <= 8 && now.getMinutes() <= 30) next = now;
    else next = getFirstWednesday(now);
    
  } else {
    throw new Error('Invalid cadence');
  }

  let output = next.toDateString();

  if (skip[output]) {
    next = new Date(now.valueOf() + oneDay);
    output = getNextMeeting(cadence, next, skip); 
  }

  return output;
}